<template>
  <div>
    <Transition>
      <i v-if="!approval_id" class="fas fa-spinner fa-spin"></i
    ></Transition>
    <Transition>
      <b-card v-if="approval_id">
        <b-card-body class="p-0">
          <!-- Current Status -->
          <b-row>
            <b-col>
              <h4 class="text-muted heading-small">Approval Status</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <badge
                :rounded="true"
                size="md"
                :type="
                  state_badge_style_type(form.status.previous_selection.name)
                "
                >{{ form.status.previous_selection.display_name }}
              </badge>
            </b-col>
          </b-row>

          <b-row>
            <b-col><hr /></b-col>
          </b-row>
          <!-- Form -->
          <b-row>
            <b-col>
              <validation-observer
                v-slot="{ handleSubmit }"
                ref="formValidator"
              >
                <b-form
                  @submit.prevent="handleSubmit(on_form_submit)"
                  ref="approval_status_form_submit"
                >
                  <b-row>
                    <b-col>
                      <base-input
                        label="Approval Status"
                        :error="form.status.error_message"
                        name="Approval Status"
                        :rules="{ required: true }"
                        :is_loading="$apollo.loading || form.is_loading"
                      >
                        <el-select
                          v-model="form.status.selection"
                          filterable
                          placeholder="Select"
                          :disabled="$apollo.loading || form.is_loading"
                          @change="handle_approval_state_change()"
                        >
                          <el-option
                            v-for="option in form.status.options"
                            :key="option.id"
                            :label="option.display_name"
                            :value="option.id"
                          >
                          </el-option>
                        </el-select>
                        <div
                          class="invalid-feedback"
                          style="display: block"
                          v-if="form.status.error_message"
                        >
                          {{ form.status.error_message }}
                        </div>
                      </base-input>
                      <base-input
                        label="Message"
                        :rules="{
                          required: form.message_required,
                        }"
                        type="textarea"
                        name="Message"
                        rows="5"
                        v-model="form.message"
                        :is_loading="$apollo.loading || form.is_loading"
                      >
                      </base-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="text-right">
                      <base-button
                        type="primary"
                        :pill="true"
                        native-type="submit"
                        class="mt-2"
                        :loading="form.is_loading"
                        :success="form.save_button.show_success"
                        :disabled="form.is_loading"
                        >Save

                        {{
                          form.status.selection !==
                          form.status.previous_selection.id
                            ? "New Status"
                            : "Comment"
                        }}
                      </base-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-col>
          </b-row>
          <!-- History -->
          <b-row class="pt-3">
            <b-col>
              <div class="approval-item-list" id="approval-container">
                <div class="content">
                  <approval-state-item
                    v-for="approval_state_update in approval.state_updates"
                    :key="approval_state_update.id"
                    :comment="approval_state_update.comment"
                    :created_date_time="approval_state_update.created"
                    :id="approval_state_update.id"
                    :state_display_name="
                      approval_state_update.new_state.display_name
                    "
                    :state_name="approval_state_update.new_state.name"
                    name="approval"
                  >
                  </approval-state-item>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </Transition>
  </div>
</template>

<script>
// Components
import { Select, Option } from "element-ui";
import ApprovalStateItem from "@/views/Components/Approval/ApprovalStateItem.vue";

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// Queries
import { GET_APPROVAL_APPROVAL_DETAIL } from "@/graphql/queries";

// Mutations
import { CREATE_APPROVAL_STATE_UPDATE } from "@/graphql/mutations";

function has_element(className) {
  return document.getElementsByClassName(className).length > 0;
}

function init_scrollbar(className) {
  if (has_element(className)) {
    new PerfectScrollbar(`.${className}`, { suppressScrollX: true });
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      init_scrollbar(className);
    }, 100);
  }
}

// ORM
import { processApprovalRelayData, Approval } from "@/ORM/Global/Approvals.js";

export default {
  name: "ApprovalComponent",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ApprovalStateItem,
  },
  props: {
    approval_id: {
      type: String,
      description: "ID of the approval in question",
      default: null,
    },
    show_history: {
      type: Boolean,
      description: "Weather to show the historic states",
      default: false,
    },
  },
  apollo: {
    get_approval_detail: {
      query: GET_APPROVAL_APPROVAL_DETAIL,
      result(res) {
        this.approval = processApprovalRelayData(res);
        this.form.status.selection = this.approval.state.id;
        this.form.status.previous_selection = this.approval.state;
        this.form.status.options = this.approval.item_type.supported_states;
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors);
        return false;
      },
      update(data) {
        this.apollo_data.get_approval_detail = data;
      },
      variables() {
        return {
          approval_id: this.approval_id,
        };
      },
      skip: true,
    },
  },
  data() {
    return {
      apollo_data: {
        get_approval_detail: null,
      },
      approval: new Approval(),
      form: {
        status: {
          selection: "",
          previous_selection: "",
          options: [
            {
              value: null,
              label: null,
            },
          ],
          error_message: "",
        },
        message: null,
        message_required: true,
        save_button: {
          show_success: false,
        },
        is_loading: false,
      },
      approval_states_updates: [],
    };
  },
  methods: {
    // Form Event Handlers
    on_form_submit() {
      this.form.is_loading = true;
      if (
        this.form.status.selection !== this.form.status.previous_selection.id
      ) {
        this.form.status.error_message = "";
        this.create_approval_state_update();
      } else {
        this.form.status.error_message = "No status change, comment added.";
        this.create_approval_state_update();
      }
    },

    create_approval_state_update() {
      this.$apollo
        .mutate({
          mutation: CREATE_APPROVAL_STATE_UPDATE,
          variables: {
            approval_id: this.approval_id,
            new_state_id: this.form.status.selection,
            comment: this.form.message,
            created_by_user_id: this.$store.getters.getUserIdB64,
          },
        })
        .then((res) => {
          if ("data" in res && "approvalCreateStateUpdate" in res.data) {
            this.$apollo.queries.get_approval_detail.refetch();
            this.form.save_button.show_success = true;
            setTimeout(() => {
              this.form.save_button.show_success = false;
            }, 2000);
            this.form.status.previous_selection =
              this.get_object_from_value_select_options(
                this.form.status.selection,
                this.form.status.options
              );
          }
          this.form.is_loading = false;
          global_event_emitter.$emit("listing_status_update");
        })
        .catch(() => {
          this.form.is_loading = false;
        });
    },

    // Apollo manager
    manage_get_approval_detail() {
      this.$apollo.queries.get_approval_detail.setOptions({
        fetchPolicy: "network-only",
      });

      if (this.approval_id !== null) {
        if (!this.$apollo.queries.get_approval_detail.skip) {
          this.$apollo.queries.get_approval_detail.refetch();
        } else {
          this.$apollo.queries.get_approval_detail.skip = false;
          // this.$apollo.queries.get_approval_detail.start();
        }
      } else {
        if (this.$apollo.queries.get_approval_detail.skip) {
          this.$apollo.queries.get_approval_detail.skip = true;
        }
      }
    },

    // Scroll bar
    init_scrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        init_scrollbar("approval-item-list");
      }
    },

    get_max_height_approval_history_container() {
      let approval_content_height = 0;
      let approval_elements = document.getElementsByName("approval");
      for (let i = 0; i < approval_elements.length; i++) {
        approval_content_height += approval_elements[i].clientHeight;
      }
      console.log(approval_content_height);
      if (approval_content_height < 750) {
        document.getElementById("approval-container").style.height =
          approval_content_height + "px";
      } else {
        document.getElementById("approval-container").style.height = "750px";
      }
    },

    get_object_from_value_select_options(selection, options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].id == selection) {
          return options[i];
        }
      }
      return null;
    },
    state_badge_style_type(name) {
      if (name == "PENDING_SUBMISSION") {
        return "info";
      }
      if (name == "NEEDS_REVIEW") {
        return "warning";
      }
      if (name == "APPROVED") {
        return "info";
      }
      if (name == "SUBMITTED") {
        return "primary";
      }
      return "danger";
    },

    handle_approval_state_change() {
      let status_object = this.get_object_from_value_select_options(
        this.form.status.selection,
        this.form.status.options
      );
      if ("name" in status_object && status_object.name == "APPROVED") {
        this.form.message_required = false;
      } else {
        this.form.message_required = true;
      }
    },
  },
  watch: {
    approval_id() {
      this.manage_get_approval_detail();
    },
    approval_states_updates() {
      setTimeout(() => {
        this.get_max_height_approval_history_container();
      }, 200);
    },
  },
  mounted() {
    this.manage_get_approval_detail();
    this.init_scrollbar();
  },
};
</script>

<style>
.el-input__inner {
  border-radius: 10rem;
}

#approval-container {
  /* background-color: rgb(255, 255, 255); */
  position: relative;
  /* margin: 10px auto; */
  /* padding: 0px; */
  /* height: 500px; */
  overflow-y: scroll;
  overflow-x: hidden;
}

/* #approval-container .content { */
/* height: 900px; */
/* } */
</style>
